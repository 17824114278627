<template>
  <section>
    <div class="headbanner" :style="`background-image:url(${bannerUrl});`">
      <!-- <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Privacy Policy</h1></v-col>
        </v-row>
      </v-container> -->
    </div>
    <v-container class="container-custom">
      <div class="boxinside">
        <h1>We take our visitors privacy seriously.</h1>
        <p>
          We are committed to respecting your online privacy and recognize your
          need for appropriate protection and management of any personally
          identifiable information you share with us. In order to provide a
          personalized browsing experience, Necojobs.com may collect information
          from you, this may include technical or other related information from
          the device used to access Necojobs.com including without limitation
          contact lists in your device and its location, you may also be asked
          to complete a registration form. When you let us have your
          preferences, we will be able to deliver or allow you to access the
          most relevant information that meets your end. The privacy policy
          provided with respect to our use and protection of any personal
          resume, information, and data uploaded by user through our website
        </p>

        <h3>1.Data processed by using cookie</h3>
        <p>
          In order to carry out specific activities, Necojobs.com collects User
          and Registered Users personal data (e.g. IP addresses) by using cookie
          and similar technologies. For a detailed illustration on the type of
          cookie installed on the Site and on their functions and purposes.

          <span>1.1 Technical Cookies</span>

          These cookies are essential to allow the User to browse the Site and
          use its functions, such as accessing the website’s reserved areas
          (authentication cookies). Without these kind of cookies the services
          requested, such as access to the reserved area of the Registered
          Users, cannot be provided. These cookies which are strictly necessary,
          are used to record one single identifier in order to manage and
          identify the User with respect to other Users visiting the Site at the
          same time, providing the User with a consistent and precise service.
          These cookies are necessary for the Site to work properly therefore
          they does not need the Users prior consent.

          <span
            >1.2 Performance and analytics cookies set by Necojobs.com and
            similar technologies</span
          >

          These cookies may be used by necojobs.com for the purposes of
          performance and improving the Site. These cookies gather information
          about how users use the Site, which pages are visited for example, and
          allows Necojobs.com to recognize Users, trace their online behaviors
          and identify which features of the website are of most interest to
          Users.

          <span>1.3 First Party Profiling Cookies</span>

          These cookies are installed in relation to the contents visualized by
          the User and their utilization of the Website. These cookies allow the
          Website to record the preferences expressed by the User in their
          browsing, to analyse their behaviour, and customize specific Website
          contents to each single User, for the purpose of developing content
          and sending promotional messages in line with the preferences
          expressed by the User as they navigate the Website. This type of
          cookies may also be used to restrict the number of visualizations of
          specific content and to analyse the results acquired through specific
          actions on the Website. The Owner may also add web beacons in web
          pages or e-mails for the purpose of tracking clicks on the links or
          images featured, and the Users’ opening of e-mails containing job
          adverts and business newsletters. This information is collected to
          monitor the number of Users that visit the Website pages or click on
          the e-mails containig job adverts and the newsletters, allowing the
          Owner to obtain statistical information and identify the
          characteristics and contents preferred by Users, in order to send them
          customized information. These cookies are: JE , JE_GLOBAL.

          <span>1.3 Third-Party Profiling Cookies</span>

          Necojob.com uses business partners to display advertisement
          communications on the Website and its services on third-party
          websites, and to enable its advertisement network partners to display
          advertisment content connected with the preferences shown by the Users
          who registered with the Website or browsed through it. These cookies
          are used by Google and other third parties (such as Appnexus, Rubicon
          Project, Pubmatic, Criteo, Index Exchange and others, as listed here:
          Advertising Partners List ) for the purpose of displaying
          advertisement banners to Users in their browsing. These cookies are
          also used to show Users products or services they are interested in,
          or similar to those visualized, based on their browsing history.
          <br /><br />
          These cookies connect to the User’s browser or other device used by
          Users to browse the Internet. For further information on how to manage
          this type of cookies and on how to refuse their utilization, please
          see Google’s privacy policy (
          https://policies.google.com/technologies/ads ;
          https://policies.google.com/technologies/partner- sites ;
          https://adssettings.google.com/ ) and the policies used by the
          third-parties mentioned above, and apply the available options at the
          following link: http://www.youronlinechoices.com/ .

          <span>1.4 How to disable cookies</span>

          Please note that, also after having provided the consent by overcoming
          the banner cookie on the landing page of the Site, if you wish, you
          may set your browser to limit the number of cookies accepted or
          disable all cookies, by changing the browser’s settings with the
          following procedures:
        </p>

        <h3>What are the data protection rights of the users?</h3>
        <p>
          The users have the following rights: - The right to request access to
          their personal data: you may ask Necojobs.com if it is processing your
          data and, if so, gain access to said data. - The right to request that
          said data be rectified if they are inaccurate or completed if
          incomplete. - The right to request the erasure of their data. - The
          right to request a limited processing of their data: in this case we
          will only keep your data with a view to exercising or defending any
          possible claims. - The right to object to the processing: Necojobs.com
          will cease to process your personal data except for legitimate reasons
          and/or in order to exercise or defend any claims. - The right to the
          portability of the data: if you wish to have your data processed by a
          different data controller, Necojobs.com will enable the transfer of
          said data to the new controller. - The right not to be subject to a
          decision based solely on the automated processing of their personal
          data.
        </p>

        <h3>How do we use the personal data of our users?</h3>
        <p>
          Necojobs.com hereby communicates to any users applicable that any
          personal data which they may provide and we may capture will be
          identified in the Register of Processing Activities held by
          Necojobs.com, and may be used for the following purposes: - To
          register the user and generate a new Necojobs,com user account. - To
          provide our services under the Legal Notice and General Conditions of
          Necojobs.com or those that may be established in the future with each
          of the users. - To enable access to any options requiring registration
          (e.g., sending CVs or employment applications, accessing options
          within the account profile, activating custom alerts) - To respond to
          consultations or information requests made by the users.
        </p>

        <h3>How do we protect your personal data?</h3>
        <p>
          Necojobs.com has security measures in place to prevent your personal
          information from being accidentally lost, used or accessed in an
          unauthorized way, or inappropriately altered or disclosed. In
          addition, we limit access to your personal information to those who
          need to process that information for business reasons. They will only
          process your personal information on our instructions and they are
          subject to a duty of confidentiality. We have put in place procedures
          to deal with any suspected information security breach and will notify
          you and any applicable regulator of a suspected breach as appropriate
          and in accordance with our legal obligations. However, despite the
          diligent implementation of such measures, the user must know that
          Internet security measures are not impregnable.
        </p>

        <h3>Confidentiality</h3>
        <p>
          Professionals working with Necojobs.com who may intervene in any way
          in the services provided to the user undertake not to disclose or use
          the information they may gain access to. In any event, any information
          provided by the user will be considered confidential and may not be
          used for purposes other than the management of the user account and/or
          the services engaged with necojobs.com, if any. In this regard, we
          undertake not to disclose information regarding the purposes of the
          user, the information requested or the duration of our relationship
          with the user
        </p>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Privacy Policy",
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
  }),
  methods: {
    ...mapActions(["getAssets","getSingleMetaPage"]),
  },
  computed: {
    ...mapGetters(["allAssets","allMeta"]),
  },

  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
    };
  },
  async created() {
    await this.getAssets({
      title: "PostAJob",
      connectedTo: "page",
    });
    this.bannerUrl = this.allAssets[0].image;

    await this.getSingleMetaPage("privacy-policy");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  padding: 10px 40px;
  background: $white;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-bottom: 30px !important;
  &:last-of-type {
    margin-bottom: 0;
  }

  h1 {
    font-size: $titlefont;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  h3 {
    font-size: $titlefonth4;
    font-weight: 500;
    margin: 10px 0;
  }
  p {
    font-size: $normal_lfont;
    color: $paragraphcolor;
  }
  span {
    margin: 7px 0;
    display: block;
    font-weight: 500;
  }
}

.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position: center center;
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .boxinside {
    padding: 10px 5px;
    overflow: hidden;
  }
}
</style>
